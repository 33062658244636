import React, { useState, useEffect, useRef } from 'react';
import { FaAmazon, FaGoodreads, FaInstagram } from 'react-icons/fa'; // Import the icon you want to use
import { PiDiamondThin } from "react-icons/pi";
import { Link, useLocation } from 'react-router-dom';
import logo from './assets/logo.png';

const Header: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null); // Ref for the header
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      headerRef.current?.classList.add('h-full');
      document.body.classList.add('overflow-hidden');
    } else {
      headerRef.current?.classList.remove('h-full');
      document.body.classList.remove('overflow-hidden');
    }
  };

  const handleLinkClick = (page: string) => {
    setActivePage(page);
    setMenuOpen(false);
    headerRef.current?.classList.remove('h-full');
    document.body.classList.remove('overflow-hidden');  // Ensure scrolling is enabled when navigating away
    closeDropdown();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header ref={headerRef} className="md:pb-0 md:h-20 bg-gradient-to-b md:min-h-0 from-[#000000] to-[#2F2645] text-white flex flex-col md:flex-row items-center justify-between z-50 fixed top-0 left-0 w-full">
      <div className="flex flex-row items-center justify-between w-full md:w-1/3 md:space-y-0 md:space-x-0 font-logo">
        <Link
          to="/"
          className="flex-1 block text-left justify-center pl-5 md:hover:bg-[#2F2645] focus:outline-none"
          onClick={() => handleLinkClick('/')}
          onMouseDown={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
        >
          <img src={logo} className="ml-0 h-[80px]" alt="handwritten name" />
        </Link>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="p-4 space-y-0 text-4xl focus:outline-none">
            &#9776;
          </button>
        </div>
      </div>
      <nav className={`flex-col md:flex-row space-y-2 sm:space-y-0 items-center justify-between w-full md:w-2/3 md:space-y-0 md:space-x-0 font-header text-6xl ${menuOpen ? 'flex' : 'hidden'} md:flex`}>
        <Link
          to="/"
          className="w-full flex-1 block text-center px-6 py-2 md:pr-2 md:pl-4 hover:bg-[#2F2645] focus:outline-none"
          onClick={() => handleLinkClick('/')}
          onMouseDown={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
        >
          <div className="flex items-center justify-center">
            {activePage === '/' && <PiDiamondThin className="h-5 text-white md:hidden mr-2" />}
            Home
          </div>
        </Link>
        <div className="w-full relative flex-1 block text-center px-6 py-2 md:pr-2 md:pl-4 md:hover:bg-[#2F2645]" ref={dropdownRef}>
          <button onClick={toggleDropdown} className="flex items-center justify-center font-header w-full h-full focus:outline-none">
            <div className="flex items-center justify-center">
              {activePage === '/vof' && <PiDiamondThin className="h-5 text-white md:hidden mr-2" />}
            </div>

            Books
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 md:w-auto p-0 text-white rounded shadow-2xl bg-[#463a65]">
              <Link
                to="/vof"
                className="block font-header w-full py-14 px-20 md:py-8 md:px-5 md:text-4xl text-7xl hover:bg-[#2B203D] focus:outline-none"
                onClick={() => handleLinkClick('/vof')}
                onMouseDown={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
              >
                <div className="flex items-center z-50">
                  {activePage === '/vof' && <PiDiamondThin className="h-5 text-white md:hidden mr-2" />}
                  Vials of Fate Trilogy
                </div>
              </Link>
            </div>
          )}
        </div>
        <Link
          to="/about"
          className="flex-1 w-full block text-center px-6 py-2 md:pr-2 md:pl-4 hover:bg-[#2F2645] focus:outline-none"
          onClick={() => handleLinkClick('/about')}
          onMouseDown={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
        >
          <div className="flex items-center justify-center">
            {activePage === '/about' && <PiDiamondThin className="h-5 text-white md:hidden mr-2" />}
            About
          </div>
        </Link>
        <div className="font-text pb-40 flex md:flex-row flex-col text-left pt-14 md:py-0 lg:py-4 md:px-6 space-y-0 md:space-y-0 sm:space-x-0 md:space-x-1 lg:space-x-4 text-sm focus:outline-none">
          <a className="w-full flex flex-row" href="https://www.instagram.com/catalinamateiwrites" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <div className="md:w-full w-1/3 flex">
              <FaInstagram className="w-12 h-12 hover:text-gray-400" />
            </div>
            <div className="md:hidden w-2/3 md:pl-2 lg:pl-5 text-left">@CATALINAMATEIWRITES</div>
          </a>
          <a className="w-full flex flex-row items-center" href="https://www.goodreads.com/catalinamatei" target="_blank" rel="noopener noreferrer" aria-label="Goodreads">
            <div className="md:w-full  w-1/3 flex">
              <FaGoodreads className="w-12 h-12 hover:text-gray-400" />
            </div>
            <div className="md:hidden w-2/3 md:pl-2 lg:pl-5 text-left">CATALINAMATEI</div>
          </a>
          <a className="w-full flex flex-row items-center" href="https://www.amazon.com/stores/Catalina-Matei/author/B0D6WHPXSP?ref=ap_rdr&isDramIntegrated=true&shoppingPortalEnabled=true" target="_blank" rel="noopener noreferrer" aria-label="Goodreads">
            <div className="md:w-full  w-1/3 flex">
              <FaAmazon className="w-12 h-12 hover:text-gray-400" />
            </div>
            <div className="md:hidden w-2/3 md:pl-2 lg:pl-5 text-left">CATALINA-MATEI</div>
          </a>
        </div>


      </nav>
    </header>
  );
};

export default Header;
