import React from 'react';
import { FaGoodreads, FaInstagram } from 'react-icons/fa';

const Contact: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="py-5 w-[1080px] h-[1080px]">

        <div className="flex mt-4 space-x-5">
          <p className='mt-3'>You can find me on:</p>
          <a href="https://www.instagram.com/catalinamateiwrites" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram className="w-12 h-12 hover:text-gray-400" />
          </a>
          <a href="https://www.goodreads.com/catalinamatei" target="_blank" rel="noopener noreferrer" aria-label="Goodreads">
            <FaGoodreads className="w-12 h-12 hover:text-gray-400" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
