import React from 'react';
import series from './assets/series.png';

import footercs from './assets/footercs.svg';
import titleCrown from './assets/titlecrown.svg';
import { FaAmazon } from 'react-icons/fa';
const Book1: React.FC = () => {
  return (
    <div className="flex  justify-center min-h-screen">
      <div className="pt-64 w-full md:w-[1080px]">
        <div className='flex flex-col items-center pb-20 font-fantasy'>
          <h1 className=" text-center text-5xl">Vials of Fate: Trilogy</h1>
          <img className="-mt-5 w-[500px]" src={titleCrown} alt="title svg underline crown" />
        </div>

        <div className='flex flex-col items-center'>

          <img className="pt-5" src={series} alt="series" />
          <img className="pt-5" src={footercs} alt="series" />
          <div className="tracking-wide text-justify break-words mx-4 font-text text-xl my-4 pt-5 mb-8 drop-shadow-2xl">
            A new epic romantic fantasy trilogy that features magical beings and creatures, deadly mental trials and a steamy romance in the midst of a journey towards liberation and self discovery.
          </div>
        </div>
        <a href=" https://www.amazon.com/dp/B0D6W59KG3" className='items-center ' target="_blank" rel="noopener noreferrer" aria-label="Amazon">
          <div className='flex justify-center space-x-5 pt-20 text-2xl font-text hover:text-gray-400'>ORDER NOW
            <FaAmazon className="w-12 h-8 hover:text-gray-400" />
          </div>
        </a>

      </div>
    </div>

  );
};

export default Book1;
