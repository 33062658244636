import React from 'react';
import { FaInstagram, FaGoodreads, FaEnvelope } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="font-text text-white pb-20 pt-64 min-h-[200px]">
            <div className="flex center items-center space-x-4 justify-center mb-2 font-medium">Connect with me</div>
            <div className="flex center items-center space-x-4 justify-center">
                <a href="https://www.instagram.com/catalinamateiwrites" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                    <FaInstagram className="w-6 h-6 hover:text-gray-400" />
                </a>
                <a href="https://www.goodreads.com/catalinamatei" target="_blank" rel="noopener noreferrer" aria-label="Goodreads">
                    <FaGoodreads className="w-6 h-6 hover:text-gray-400" />
                </a>
                <a href="mailto:books@catalinamatei.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
                    <FaEnvelope className="w-6 h-6 hover:text-gray-400" />
                </a>

            </div>
            <div className="mx-auto flex flex-col items-center justify-between mt-6">
                <div className="flex flex-col md:flex-row items-center mb-4 ">
                    <a href="/" className="hover:underline m-2 ">Home</a>
                    <a href="/vof" className="hover:underline m-2">Books</a>
                    <a href="/about" className="hover:underline m-2 ">About</a>
                </div>
            </div>
            <div className="text-center mb-12">
                <p>&copy; 2024 <span className="font-medium tracking-wider">Catalina Matei</span>. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
