import React from 'react';
import myImage from './assets/catalina.jpeg';
import titleCrown from './assets/titlecrown.svg';
const About: React.FC = () => {

  return (
    <div className="flex  justify-center min-h-screen">
      <div className="pt-64 w-full md:w-[1080px]">



        <div className='flex flex-col items-center pb-20 font-fantasy'>
          <h1 className=" text-center text-6xl">About Me</h1>
          <img className="-mt-5 w-[500px]" src={titleCrown} alt="title svg underline crown" />
        </div>


        <div className="flex flex-col items-center md:flex-row md:items-center">
          <div className="md:px-10 pl-2 md:w-1/2 flex">
            <img className='md:scale-150 pt-10 md:pt-14 md:w-full w-80' src={myImage} alt="Catalina Matei" />
          </div>

          <div className="flex text-justify text-wider flex-col items-center md:items-start md:justify-center">
            <div className='px-10 pt-10 md:pt-16 text-xl font-text md:px-12'>
              <div className='mb-8 drop-shadow-2xl md:text-left'>I write about existential angst in steamy fantasy novels.</div>
              <div className='mb-8 drop-shadow-2xl md:text-left'>Even though I started writing in high school, I never thought I'd get here and start an entire trilogy but here we are.</div>
              <div className='mb-8 drop-shadow-2xl md:text-left'>I'm a bookworm at heart and enjoy reading various genres from classics to pitch black horror romances.</div>
              <div className='mb-8 drop-shadow-2xl md:text-left'>I live in Romania and when I'm not working my full time job as a software engineer, I spend my days off on road trips through Europe with my husband and overprotective pug, reading and/or writing.</div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default About;
